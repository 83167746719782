<template>
  <div class="wrapper">
    <div v-if="isOpen" class="chat">
      <button type="button" class="close-btn" @click="close">
        <CloseIcon />
      </button>
      <p class="chat-title">Чат</p>
    </div>
    <button class="icon-btn" type="button" @click="openChat">
      <TalkIcon />
    </button>
  </div>
</template>

<script>
import TalkIcon from './components/TalkIcon.vue'
import CloseIcon from './components/CloseIcon.vue'

export default {
  name: 'ChatTool',
  components: {
    TalkIcon,
    CloseIcon
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    close() {
      this.isOpen = false
    },
    openChat() {
      this.isOpen = true
    }
  }
}
</script>

<style lang="stylus" scoped>
.chat
  position: relative
  bottom -36px
  width 351px
  height 391px
  border-radius 10px
  background #fff
  box-shadow 5px 5px 5px 5px rgba(0, 0, 0, 0.1)
  z-index 10
  .close-btn
    display flex
    justify-content center
    align-items center
    position absolute
    top 10px
    right 10px
    background: #EDEFF3;
    width 36px
    height 36px
    border-radius 50%
    outline none
  .chat-title
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    font-weight: 600;
    font-size: 36px;
    line-height: 120%;
.icon-btn
  background #0071c5
  width 36px
  height 36px
  border-radius 50%
</style>
